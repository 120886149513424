/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"

if (typeof window !== "undefined") {
  window.jQuery = window.$ = require("jquery")
  require("bootstrap")
  require("slick-carousel")

  $(document).ready(function () {
    /* Start Header */
    $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open')
      });

      $(function() {
        let deviceCheck;
        if (window.matchMedia("(min-width: 700px)").matches) {
            
            var header = $(".navbar");
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();

                if (scroll >= 180) {
                    header.addClass("navbar-tiny");
                } else {
                    header.removeClass("navbar-tiny");
                }
            });
        }
    });

    $("#navbar-search-form").submit(function(event) {
        event.preventDefault();

        var searchString = $('#navbar-search-input').val();

        if(searchString) {
            window.location = `https://www.penny.ro/search/${searchString}`;
        }
    });

    /* End Header */
  });
}
